import React from "react";
import { Box, Heading, Text, HStack, Badge, Flex, Spacer } from "@chakra-ui/react";
import { AddComma } from "@util/number";

type ListHeaderProp = {
  count?: number;
  title: string;
  subTitle: string;
  actions?: any;
};

const ListHeader: React.FC<ListHeaderProp> = ({ count, title, subTitle, actions }) => {
  return (
    <HStack justify="space-between" align="flex-end">
      <Box>
        <Flex alignItems="flex-end">
          <Heading color="gray.700" fontSize="26px" mb={1}>
            {title}
          </Heading>
          {!!count && <Badge colorScheme="green" fontSize="0.8em" ml="2" mb="1">{`${AddComma(count)}건`}</Badge>}
        </Flex>
        <Text color="gray.400">{subTitle}</Text>
      </Box>
      <Spacer />
      {actions && actions}
    </HStack>
  );
};

export default ListHeader;
