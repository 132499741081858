import useSWR from "swr";

export const useUser = () => {
  const { data, error, mutate } = useSWR(`auth/me`, { loadingTimeout: 500 });
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    userMutate: mutate,
  };
};
