import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Divider, SimpleGrid, Text, Textarea, HStack } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import { EditIcon } from "@chakra-ui/icons";
import { INIT_STATE, LABEL } from "./config";
import TextInput from "@component/TextInput";
import useModal from "@hooks/useModal";
import useSWR from "swr";
import { API } from "./config";
import ListHeader from "@layout/ListHeader";
import { addEntity, updateEntity } from "@services/common";
import { ACTION, reducer } from "@type/context";
import SwitchInput from "@component/SwitchInput";
import { useImageUpload } from "@hooks/useImageUpload";
import uploadImage from "@services/image";
import QuilEditor from "@component/QuilEditor";
import UploadFile from "@component/UploadFile";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Files from "@component/Files";
import Addresss from "@component/Addresss";
import { sendSms } from "@services/etc";

const DetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, mutate } = useSWR(id ? `${API}/${id}` : null);
  const isUpdate = !!id;
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, INIT_STATE);
  const [content, setContent] = React.useState("");
  const { images, UploadImage } = useImageUpload(state.img, () =>
    dispatch({
      type: ACTION.UPDATE,
      payload: { name: "img", value: "" },
    })
  );

  const { success, fail } = useModal();

  React.useEffect(() => {
    if (data) dispatch({ type: ACTION.SET, payload: { data } });
  }, [data]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let img = state.img;
    if (images.length > 0) {
      img = await uploadImage(images[0].file);
    }
    let contractFile = state.contractFile;
    if (state.contractFile && typeof state.contractFile !== "string") {
      contractFile = await uploadImage(state.contractFile);
    }
    try {
      if (isUpdate) {
        const { id, createdAt, updatedAt, requests, ...rest } = state;
        await updateEntity(API, id, { ...rest, img, contractFile });
        await mutate();
        success();
      } else {
        await addEntity(API, { ...state, img, contractFile });
        history.goBack();
      }
    } catch (err) {
      fail();
    }
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    dispatch({ type: ACTION.UPDATE, payload: { name, value } });
  };

  const handleToggle = (name: string, e: React.ChangeEvent<any>) => {
    dispatch({
      type: ACTION.UPDATE,
      payload: { name, value: e.target.checked },
    });
  };

  const handleContractFile = React.useCallback((value: any) => {
    dispatch({ type: ACTION.UPDATE, payload: { name: "contractFile", value } });
  }, []);

  const handleFiles = React.useCallback((value: any) => {
    dispatch({ type: ACTION.UPDATE, payload: { name: "files", value } });
  }, []);

  const handleAddresss = React.useCallback((value: any) => {
    dispatch({ type: ACTION.UPDATE, payload: { name: "addresss", value } });
  }, []);

  const handleSend = async () => {
    if (!content) {
      fail();
    } else {
      await sendSms({ id: +id, content });
      setContent("");
      success();
    }
  };

  const handleUser = () => {
    history.push(`/request?start=0&limit=10&filterBy=postId&filter=${id}&sortBy=ASC&sort=`);
  };

  return (
    <Layout>
      <Wrapper>
        <Tabs>
          <form onSubmit={handleSubmit}>
            <ListHeader
              title={LABEL.title + " 디테일"}
              subTitle={LABEL.title + `를 ${isUpdate ? "수정" : "생성"}하세요`}
              actions={
                <HStack>
                  <Button size="sm" onClick={handleUser}>
                    {"참여자 보기"}
                  </Button>
                  <Button type="submit" colorScheme="teal" leftIcon={<EditIcon />} size="sm">
                    {isUpdate ? "수정완료" : "생성"}
                  </Button>
                </HStack>
              }
            />
            <Divider my={4} />
            <TabList>
              <Tab>기본정보</Tab>
              <Tab>상세정보</Tab>
              <Tab>필요사항</Tab>
              <Tab>주소목록</Tab>
              <Tab>문자전송</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <>
                  <SwitchInput name="published" label="진행여부" value={state.published} handleChange={handleToggle} />
                  <Box h={4} />
                  <Text mb={2} fontWeight="500">
                    메인 이미지
                  </Text>
                  <UploadImage />
                  <Box mb={4} />
                  <TextInput
                    name="apart"
                    label="아파트명"
                    placeholder="아파트명을 입력하세요."
                    value={state.apart}
                    handleChange={handleChange}
                  />
                  <Box h={4} />
                  <TextInput
                    name="title"
                    label="제목"
                    placeholder="제목을 입력하세요."
                    value={state.title}
                    handleChange={handleChange}
                  />
                  <Box h={4} />
                  <Text mb={2} fontWeight="500">
                    내용
                  </Text>
                  <QuilEditor
                    value={state.content}
                    onChange={(value: string) => {
                      dispatch({
                        type: ACTION.UPDATE,
                        payload: { name: "content", value },
                      });
                    }}
                  />
                </>
              </TabPanel>
              <TabPanel px={0}>
                <>
                  <SimpleGrid columns={[1, 2]} spacing={4}>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        전문가가 알려드립니다.
                      </Text>
                      <QuilEditor
                        value={state.notify}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "notify", value },
                          });
                        }}
                      />
                    </Box>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        비용안내
                      </Text>
                      <QuilEditor
                        value={state.expense}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "expense", value },
                          });
                        }}
                      />
                    </Box>
                  </SimpleGrid>
                  <Box h={[24, 12]} />
                  <SimpleGrid columns={[1, 2]} spacing={4}>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        위임사무의 범위
                      </Text>
                      <QuilEditor
                        value={state.scope}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "scope", value },
                          });
                        }}
                      />
                    </Box>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        참여 대상 안내
                      </Text>
                      <QuilEditor
                        value={state.target}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "target", value },
                          });
                        }}
                      />
                    </Box>
                  </SimpleGrid>
                  <Box h={[24, 12]} />
                  <SimpleGrid columns={[1, 2]} spacing={4}>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        제출 필요 서류
                      </Text>
                      <QuilEditor
                        value={state.paper}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "paper", value },
                          });
                        }}
                      />
                    </Box>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        환급 승소 가능성
                      </Text>
                      <QuilEditor
                        value={state.posibility}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "posibility", value },
                          });
                        }}
                      />
                    </Box>
                  </SimpleGrid>
                  <Box h={[24, 12]} />
                  <SimpleGrid columns={[1, 2]} spacing={4}>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        참여 시 위험부담
                      </Text>
                      <QuilEditor
                        value={state.risk}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "risk", value },
                          });
                        }}
                      />
                    </Box>
                    <Box>
                      <Text mb={2} fontWeight="500">
                        사전안내
                      </Text>
                      <QuilEditor
                        value={state.notice}
                        onChange={(value: string) => {
                          dispatch({
                            type: ACTION.UPDATE,
                            payload: { name: "notice", value },
                          });
                        }}
                      />
                    </Box>
                  </SimpleGrid>
                  <Box h={[24, 12]} />
                  <Text mb={2} fontWeight="500">
                    담당 변호사
                  </Text>
                  <QuilEditor
                    value={state.lawyer}
                    onChange={(value: string) => {
                      dispatch({
                        type: ACTION.UPDATE,
                        payload: { name: "lawyer", value },
                      });
                    }}
                  />
                </>
              </TabPanel>
              <TabPanel px={0}>
                <>
                  <Text mb={2} fontWeight="500">
                    사건위임계약서
                  </Text>
                  <QuilEditor
                    value={state.contract}
                    onChange={(value: string) => {
                      dispatch({
                        type: ACTION.UPDATE,
                        payload: { name: "contract", value },
                      });
                    }}
                  />
                  <Box h={4} />
                  <Text mb={2} fontWeight="500">
                    사건위임계약서 파일
                  </Text>
                  <UploadFile
                    value={
                      state.contractFile && typeof state.contractFile === "string"
                        ? state.contractFile?.split("/")[4]
                        : ""
                    }
                    setValue={handleContractFile}
                  />
                  <Box h={4} />
                  <Files files={state.files} setValue={handleFiles} />
                </>
              </TabPanel>
              <TabPanel px={0}>
                <Addresss addresss={state.addresss || []} setValue={handleAddresss} />
              </TabPanel>
              <TabPanel px={0}>
                <>
                  <Text mb={2} fontWeight="500">
                    문자내용
                  </Text>
                  <Textarea
                    placeholder="내용을 입력하세요."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                  <Button mt={4} onClick={handleSend}>
                    SMS보내기
                  </Button>
                </>
              </TabPanel>
            </TabPanels>
          </form>
        </Tabs>
      </Wrapper>
    </Layout>
  );
};

export default DetailPage;
