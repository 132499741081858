import React from "react";
import { Select, SelectProps, FormControl, FormLabel } from "@chakra-ui/react";

interface Option {
  label: string;
  value: string;
}

interface SelectInputProp extends SelectProps {
  w?: any;
  name: string;
  label?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
}

const SelectInput: React.FC<SelectInputProp> = ({ w, name, label, value, handleChange, options, ...rest }) => {
  return (
    <FormControl id={value} w={w}>
      <FormLabel>{label}</FormLabel>
      <Select name={name} rounded="none" value={value} onChange={handleChange} {...rest}>
        {options.map((el: Option) => (
          <option key={el.value} value={el.value}>
            {el.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
