import { useToast } from "@chakra-ui/react";

const useModal = () => {
  const toast = useToast();

  const success = () => {
    toast({
      title: "Awoo",
      description: "업데이트 성공",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };
  const fail = () => {
    toast({
      title: "Awoo",
      description: "업데이트 실패",
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };
  return { success, fail };
};

export default useModal;
