import React from "react";
import { Box } from "@chakra-ui/react";

type WrapperProp = {
  mt?: number;
};

const Wrapper: React.FC<WrapperProp> = ({ mt = 0, children }) => {
  return (
    <Box p={[2, 4]} mt={[-2, mt]}>
      <Box border={["none", "1px solid #eee"]} p={[2, 5]} boxShadow={["none", "sm"]} bg="#fff">
        {children}
      </Box>
    </Box>
  );
};

export default Wrapper;
