import React from "react";
import { Button } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

interface Prop {
  data: any[];
  callback: () => void;
}

const useDeletesModal = ({ data, callback }: Prop) => {
  const [isOpen, setDeletesOpen] = React.useState(false);
  const handleClick = () => {
    callback();
    setDeletesOpen(false);
  };
  const cancelRef = React.useRef(null);
  const handleClose = () => setDeletesOpen(false);

  const DeletesModal = () => (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            데이터 삭제
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>정말 데이터 삭제를 원하십니까?</Text>
            {data.map((el: any) => (
              <Text key={el.id}>{el.id}</Text>
            ))}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              취소
            </Button>
            <Button colorScheme="red" onClick={handleClick} ml={3} leftIcon={<DeleteIcon />}>
              모두 삭제
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
  return { setDeletesOpen, DeletesModal };
};

export default useDeletesModal;
