import useSWR from "swr";

const useEntities = (api: string, query: string) => {
  const page = +(localStorage.getItem("page") ?? 10);
  const queries = new URLSearchParams(query);
  const start = +(queries.get("start") ?? 0);
  const limit = +(queries.get("limit") ?? page);
  const filter = queries.get("filter");
  const filterBy = queries.get("filterBy");
  const sort = queries.get("sort");
  const sortBy = queries.get("sortBy");

  const { data, error, mutate } = useSWR(() => {
    let query = `start=${start}&limit=${limit}`;
    if (sort) {
      query += `&sort=${sort}:${sortBy}`;
    } else {
      query += "&sort=id:DESC";
    }
    if (filter) query += `&${filterBy}=${filter}`;
    return `${api}?${query}`;
  });

  return {
    count: data ? data.totalCount : 0,
    pageCount: data ? Math.ceil(data.totalCount / limit) : 0,
    data: data?.data || [],
    isLoading: !error && !data,
    isError: error,
    dataMutate: mutate,
  } as {
    count: number;
    pageCount: number;
    data: any[];
    isLoading: boolean;
    isError: any;
    dataMutate: any;
  };
};

export default useEntities;
