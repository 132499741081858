/* @ts-ignore */
import S3 from "react-aws-s3";

const config = {
  bucketName: "myrefund-s3",
  dirName: "test",
  region: "ap-northeast-2",
  accessKeyId: "AKIA5YYAPNTGKQOBMRG5",
  secretAccessKey: "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",
};

const ReactS3Client = new S3(config);

const uploadImage = async (file: File) => {
  const now = new Date().toISOString();
  const { location } = await ReactS3Client.uploadFile(file, `${now}_${file.name}`);
  return location;
};

export default uploadImage;
