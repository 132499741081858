import React from "react";
import { Flex, Box, Heading, Text } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <Flex w={"100vw"} h={"100vh"} align="center" justify="center" textAlign="center">
      <Box>
        <Heading mb={2} size="2xl" color="gray.700">
          Not Found
        </Heading>
        <Text color="gray.500">페이지가 없습니다.</Text>
      </Box>
    </Flex>
  );
};

export default NotFound;
