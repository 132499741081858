import React from "react";
import { Flex, VStack, Text, Button, Divider, Box } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { useUser } from "@data/useUser";
import { AiOutlineHome, AiOutlineUp } from "react-icons/ai";
import { ROUTE, TITLE } from "@constants/config";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { selectLayout, setMenuList } from "@store/slices/layout";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

interface MenuItemProp {
  label: string;
  to: string;
  icon?: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProp> = ({ label, to, icon, onClick, active }) => {
  return (
    <Link to={to} style={{ width: "100%" }}>
      <Flex
        align="center"
        _hover={{ cursor: "pointer", bg: "gray.50" }}
        p={2}
        onClick={onClick}
        bg={active ? "gray.100" : "transparent"}
        transition="all 0.3s"
        rounded="sm"
        color={active ? "gray.800" : "gray.600"}
      >
        {icon}
        <Text as="span" ml={icon ? 3 : 0} fontSize={14}>
          {label}
        </Text>
      </Flex>
    </Link>
  );
};

const MenuButton = ({ el }: any) => {
  const dispatch = useAppDispatch();
  const { menuList } = useAppSelector(selectLayout);
  const { pathname } = useLocation();

  const handleOpen = () => {
    const menus = menuList.map((e: any) => (e.name === el.name ? { name: e.name, open: !e.open } : e));
    dispatch(setMenuList(menus));
  };
  const isOpen = menuList.find((e: any) => e.name === el.name)?.open;

  return (
    <>
      <Flex w="full" p={2} pt={5} align="center" justify="space-between" cursor="pointer" onClick={handleOpen}>
        <Text color="gray.500">{el.name}</Text>
        <Box transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"} transition="all 0.2s">
          <AiOutlineUp size={12} color="#888" />
        </Box>
      </Flex>
      {isOpen &&
        el.menus.map((menu: any) => {
          const path = pathname.split("/")[1];
          const val = menu.value.split("/")[1];
          return (
            <MenuItem
              key={menu.label + "_side_menu"}
              label={menu.label}
              to={menu.value}
              icon={<menu.icon size={16} />}
              active={path === val}
            />
          );
        })}
    </>
  );
};

const SideBar = () => {
  const { pathname } = useLocation();
  const { userMutate } = useUser();
  const dispatch = useAppDispatch();
  const { menuList } = useAppSelector(selectLayout);

  React.useEffect(() => {
    if (menuList.length === 0) {
      dispatch(setMenuList(ROUTE.map((el) => ({ name: el.name, open: true }))));
    }
  }, [menuList, dispatch]);

  return (
    <>
      <Flex flexDirection="column" minW={240} d={["none", "flex"]} borderRightWidth={1} maxH={"100vh"}>
        <Text color="gray.700" fontWeight="bold" fontSize="xl" p={5}>
          {TITLE}
        </Text>
        <Divider />
        <PerfectScrollbar>
          <VStack flex={1} fontSize={13} p={2} spacing={0}>
            <MenuItem label="HOME" to="/" active={pathname === "/"} icon={<AiOutlineHome size={16} />} />
            {ROUTE.map((el) => (
              <MenuButton key={el.name} el={el} />
            ))}
          </VStack>
        </PerfectScrollbar>
        <Divider />
        <Button
          p={6}
          rounded="none"
          leftIcon={<AiOutlineLogout />}
          size="sm"
          variant="outline"
          border="none"
          onClick={() => {
            localStorage.removeItem("jwt");
            userMutate(null);
          }}
        >
          <Text>로그아웃</Text>
        </Button>
      </Flex>
    </>
  );
};

export default SideBar;
