import React from "react";
import { Container, Text, VStack, Heading } from "@chakra-ui/react";
import { Redirect } from "react-router-dom";
import { useUser } from "@data/useUser";

type AuthContainerProps = {
  title: string;
  subTitle: string;
};

const AuthContainer: React.FC<AuthContainerProps> = ({ title, subTitle, children }) => {
  const { user } = useUser();
  if (user && user?.userType === "ADMIN") return <Redirect to="/" />;
  return (
    <Container py={8}>
      <VStack flex={1} align="center" justify="center" position="relative">
        <Heading size="lg">{title}</Heading>
        <Text color="gray.500" mb={4}>
          {subTitle}
        </Text>
        {children}
      </VStack>
    </Container>
  );
};

export default AuthContainer;
