import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

type MenuType = { name: string; open: boolean };

interface SliceState {
  value: number;
  menuList: MenuType[];
}

const initialState: SliceState = {
  value: 0,
  menuList: [],
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setMenuList: (state, action: PayloadAction<MenuType[]>) => {
      state.menuList = action.payload;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

export const { setMenuList, incrementByAmount } = layoutSlice.actions;

export const selectLayout = (state: RootState) => state.layout;

export default layoutSlice.reducer;
