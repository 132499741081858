import { ActionMap } from "@util/type";

export enum ACTION {
  SET = "SET",
  UPDATE = "UPDATE",
}

type ActionType = {
  [ACTION.SET]: { data: any };
  [ACTION.UPDATE]: { name: string; value: string | boolean | string[] | Date | any[] };
};

type Actions = ActionMap<ActionType>[keyof ActionMap<ActionType>];

export const reducer = <T>(state: T, action: Actions) => {
  switch (action.type) {
    case ACTION.SET:
      return { ...action.payload.data };
    case ACTION.UPDATE:
      return { ...state, [action.payload.name]: action.payload.value };
    default:
      return state;
  }
};
