import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AppRoute } from "./route";
import axios from "axios";
import { SWRConfig } from "swr";
import { Provider } from "react-redux";
import { store } from "@store/store";
import theme from "./theme";

axios.defaults.baseURL = "https://api.myrefund.co.kr/";
axios.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("jwt");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);
axios.defaults.withCredentials = true;

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const App = () => {
  return (
    <SWRConfig value={{ fetcher }}>
      <Provider store={store}>
        <ChakraProvider theme={theme} resetCSS={true}>
          <AppRoute />
        </ChakraProvider>
      </Provider>
    </SWRConfig>
  );
};
