import axios from "axios";

export const getUserByPhone = (phone: string) => {
  return axios.get(`users/phone/${phone}`);
};

export const login = (body: any) => {
  return axios.post(`auth/login`, body);
};

export const getMe = () => {
  return axios.get(`auth/me`);
};
