import React from "react";
import { Box, Button, Divider, HStack, SimpleGrid } from "@chakra-ui/react";
import TextInput from "./TextInput";
import Address from "./Address";
import readXlsxFile from "read-excel-file";

type AddressProp = {
  addresss: [];
  setValue: any;
};

const Addresss: React.FC<AddressProp> = ({ addresss, setValue }) => {
  const [dong, setDong] = React.useState("");
  const [ho, setHo] = React.useState("");
  const [price, setPrice] = React.useState("");

  const handleAdd = () => {
    setValue([...addresss, { dong, ho, price }]);
    setDong("");
    setHo("");
    setPrice("");
  };

  const handleUpdate = (val: any, index: number) => {
    setValue([...addresss.map((el, i) => (i === index ? val : el))]);
  };

  const handleRemove = async (index: number) => {
    setValue([]);
    setTimeout(() => {
      setValue([...addresss.filter((el, i) => i !== index)]);
    }, 300);
  };

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleClickCsvButton = () => {
    const input = document.getElementById("csv");

    if (!input) return;
    input.click();
  };

  const handleChangeCsv = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files?.length) {
      e.target.files = null;
      return;
    }

    const data = await readXlsxFile(files[0]);
    const toValue = data.map((row) => ({
      dong: row[0]?.toString() ?? "",
      ho: row[1]?.toString() ?? "",
      price: row[2]?.toString() ?? "",
    }));
    setValue([...addresss, ...toValue]);

    e.target.files = null;
  };

  return (
    <Box>
      <HStack spacing={4}>
        <TextInput
          name="dong"
          label="동"
          placeholder="동을 입력하세요."
          value={dong}
          handleChange={(e) => setDong(e.target.value)}
        />
        <TextInput
          name="ho"
          label="호"
          placeholder="호을 입력하세요."
          value={ho}
          handleChange={(e) => setHo(e.target.value)}
        />
        <TextInput
          name="price"
          label="환급액"
          placeholder="환급액을 입력하세요."
          value={price}
          handleChange={(e) => setPrice(e.target.value)}
        />
      </HStack>
      <div style={{ display: "flex", gap: "8px" }}>
        <Button w={24} mt={2} colorScheme="teal" onClick={handleAdd}>
          {"추가"}
        </Button>
        <Button w={24} mt={2} colorScheme="teal" onClick={handleClickCsvButton}>
          {"엑셀로 추가"}
        </Button>
        <Button w={24} mt={2} colorScheme="red" onClick={handleRemoveAll}>
          {"전체 삭제"}
        </Button>
      </div>

      <input type="file" id="csv" style={{ display: "none" }} onChange={handleChangeCsv} />
      <Divider my={4} />
      <SimpleGrid columns={[1]} spacing={1}>
        {addresss.map((address: any, index: number) => (
          <Address
            index={index}
            key={index}
            address={address}
            setValue={(val: any) => handleUpdate(val, index)}
            setRemove={() => handleRemove(index)}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default React.memo(Addresss);
