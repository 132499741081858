import React from "react";
import { Container, Box, Flex } from "@chakra-ui/react";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout: React.FC = ({ children }) => {
  return (
    <Container minW="100vw" p={0}>
      <Flex>
        <SideBar />
        <Box flex={1} bg="rgba(0,0,0,0.01)">
          <Header />
          <Box minH="100vh" maxH="100vh" overflowY="scroll">
            {children}
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default Layout;
