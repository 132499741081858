import React from "react";
import { Text, HStack, Spacer, Select } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowBackIcon, ArrowForwardIcon, ArrowRightIcon } from "@chakra-ui/icons";

type TablePaginationProp = {
  pageIndex: number;
  pageSize: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: any;
  pageCount: number;
  gotoPage: (e: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (e: number) => void;
};

const TablePagination: React.FC<TablePaginationProp> = (props) => {
  const {
    pageIndex,
    pageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = props;
  return (
    <HStack my={3}>
      <IconButton
        aria-label="first"
        colorScheme="teal"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage || pageIndex === 0}
        icon={<ArrowLeftIcon />}
        size="sm"
      />
      <IconButton
        aria-label="prev"
        colorScheme="teal"
        onClick={() => previousPage()}
        disabled={!canPreviousPage || pageIndex === 0}
        icon={<ArrowBackIcon />}
        size="sm"
      />
      <IconButton
        aria-label="next"
        colorScheme="teal"
        onClick={() => nextPage()}
        disabled={!canNextPage}
        icon={<ArrowForwardIcon />}
        size="sm"
      />
      <IconButton
        aria-label="last"
        colorScheme="teal"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        icon={<ArrowRightIcon />}
        size="sm"
      />
      <Text>
        <Text as="span" fontWeight="bold" fontSize={20}>
          {pageIndex + 1}
        </Text>
        / {pageOptions.length}
      </Text>
      <Spacer />
      <Select
        w={100}
        value={pageSize}
        onChange={(e) => {
          localStorage.setItem("page", e.target.value);
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize} 건
          </option>
        ))}
      </Select>
    </HStack>
  );
};

export default TablePagination;
