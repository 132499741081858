import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, SimpleGrid, Heading, HStack, Text } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import { EditIcon } from "@chakra-ui/icons";
import { INIT_STATE, LABEL } from "./config";
import TextInput from "@component/TextInput";
import useModal from "@hooks/useModal";
import useSWR from "swr";
import { API } from "./config";
import ListHeader from "@layout/ListHeader";
import { addEntity, updateEntity } from "@services/common";
import { ACTION, reducer } from "@type/context";
import UploadFile from "@component/UploadFile";
import uploadImage from "@services/image";
import styled from "@emotion/styled";

const DetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, mutate } = useSWR(id ? `${API}/${id}` : null);
  const isUpdate = !!id;
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, INIT_STATE);

  const { success, fail } = useModal();

  React.useEffect(() => {
    if (data) dispatch({ type: ACTION.SET, payload: { data } });
  }, [data]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let contractFile = state.contractFile;
      if (state.contractFile && typeof state.contractFile !== "string") {
        contractFile = await uploadImage(state.contractFile);
      }
      if (isUpdate) {
        const { id, createdAt, updatedAt, post, user, files, identity, ...rest } = state;
        await updateEntity(API, id, { ...rest, contractFile });
        await mutate();
        success();
      } else {
        await addEntity(API, { ...state, contractFile });
        history.goBack();
      }
    } catch (err) {
      fail();
    }
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    dispatch({ type: ACTION.UPDATE, payload: { name, value } });
  };

  const handleContractFile = React.useCallback((value: any) => {
    dispatch({ type: ACTION.UPDATE, payload: { name: "contractFile", value } });
  }, []);

  return (
    <Layout>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <ListHeader
            title={LABEL.title + " 디테일"}
            subTitle={LABEL.title + `를 ${isUpdate ? "수정" : "생성"}하세요`}
            actions={
              <Button type="submit" colorScheme="teal" leftIcon={<EditIcon />} size="sm">
                {isUpdate ? "수정완료" : "생성"}
              </Button>
            }
          />
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"관련 정보"}
          </Heading>
          <Button onClick={() => history.push(`/post/${state.post?.id}`)} mr={4}>
            {state?.post?.title}
          </Button>
          <Button onClick={() => history.push(`/user/${state.user?.id}`)}>{state?.user?.username}</Button>
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"개인 정보"}
          </Heading>
          <SimpleGrid columns={[2, 5]} spacing={4}>
            <TextInput
              name="name"
              label="이름"
              placeholder="이름을 입력하세요."
              value={state.name}
              handleChange={handleChange}
            />
            <TextInput
              name="birth"
              label="생년월일"
              placeholder="생년월일을 입력하세요."
              value={state.birth}
              handleChange={handleChange}
            />
            <TextInput
              name="jumin"
              label="주민등록번호"
              placeholder="주민등록번호을 입력하세요."
              value={state.jumin}
              handleChange={handleChange}
            />
            <TextInput
              name="phone"
              label="핸드폰"
              placeholder="핸드폰을 입력하세요."
              value={state.phone}
              handleChange={handleChange}
            />
            <TextInput
              name="email"
              label="이메일"
              placeholder="이메일을 입력하세요."
              value={state.email}
              handleChange={handleChange}
            />
          </SimpleGrid>
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"거주지 정보"}
          </Heading>
          <SimpleGrid columns={[1, 2]} spacing={4}>
            <TextInput
              name="address1"
              label="거주지1"
              placeholder="거주지1을 입력하세요."
              value={state.address1}
              handleChange={handleChange}
            />
            <TextInput
              name="address1Detail"
              label="거주지1상세"
              placeholder="거주지1상세을 입력하세요."
              value={state.address1Detail}
              handleChange={handleChange}
            />
            <TextInput
              name="address2"
              label="거주지2"
              placeholder="거주지2을 입력하세요."
              value={state.address2}
              handleChange={handleChange}
            />
            <GridContainer>
              <TextInput
                name="dong"
                label="아파트 동"
                placeholder="아파트 동을 입력하세요."
                value={state.dong}
                handleChange={handleChange}
              />
              <TextInput
                name="ho"
                label="아파트 호수"
                placeholder="아파트 호수를 입력하세요."
                value={state.ho}
                handleChange={handleChange}
              />
            </GridContainer>
          </SimpleGrid>
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"계좌 정보"}
          </Heading>
          <SimpleGrid columns={[1, 3]} spacing={4}>
            <TextInput
              name="accountName"
              label="은행명"
              placeholder="은행명을 입력하세요."
              value={state.accountName}
              handleChange={handleChange}
            />
            <TextInput
              name="accountOwner"
              label="이름"
              placeholder="이름을 입력하세요."
              value={state.accountOwner}
              handleChange={handleChange}
            />
            <TextInput
              name="accountNumber"
              label="계좌번호"
              placeholder="계좌번호을 입력하세요."
              value={state.accountNumber}
              handleChange={handleChange}
            />
          </SimpleGrid>
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"요청 정보"}
          </Heading>
          <SimpleGrid columns={[1, 2]} spacing={4}>
            {state?.files?.map((el: any) => (
              <Button size="sm" key={el} as="a" href={el} target="blank">
                {el.split("/")[el.split("/").length - 1]}
              </Button>
            ))}
          </SimpleGrid>
          {state?.identity?.file && (
            <>
              <Divider my={4} />
              <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
                {"인증 정보"}
              </Heading>
              <HStack spacing={4}>
                <Button size="sm" as="a" href={state?.identity?.file} target="blank">
                  {state?.identity?.file?.split("/")[state?.identity?.file?.split("/").length - 1]}
                </Button>
                <Text bg="gray.50" p={2}>
                  {state?.identity?.username}
                </Text>
                <Text bg="gray.50" p={2}>
                  {state?.identity?.birth}
                </Text>
              </HStack>
            </>
          )}
          <Divider my={4} />
          <Heading color="gray.400" fontSize="16px" w="100%" mb={2}>
            {"계약서"}
          </Heading>
          <UploadFile
            value={
              state.contractFile && typeof state.contractFile === "string" ? state.contractFile?.split("/")[4] : ""
            }
            setValue={handleContractFile}
          />
          {state?.contractFile && typeof state.contractFile === "string" && (
            <Button size="sm" as="a" href={state?.contractFile} target="blank" mt={2}>
              {state?.contractFile?.split("/")[state?.contractFile?.split("/").length - 1]}
            </Button>
          )}
        </form>
      </Wrapper>
    </Layout>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
`;

export default DetailPage;
