import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "45em",
  md: "60em",
  lg: "75em",
  xl: "90em",
});

export default breakpoints;
