// BASE
export const ROUTE = "request";
export const API = "requests";

export const FILTER_OPTION = [
  { label: "전체", value: "search" },
  { label: "소송", value: "postId" },
];

export const LABEL = {
  title: "소송참여",
  subTitle: "소송참여리스트 관리 페이지",
};

export const INIT_STATE = {
  title: "",
  desc: "",
  img: "",
  files: [],
  publish: true,
  banner: true,
};
