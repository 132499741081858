import React from "react";
import { Heading } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";

const HomePage = () => {
  return (
    <Layout>
      <Wrapper>
        <Heading color="gray.700" size="lg" mb={2}>
          전체
        </Heading>
      </Wrapper>
    </Layout>
  );
};

export default HomePage;
