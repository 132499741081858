import React from "react";
import { EditIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

const AddButton = ({ onClick }: any) => {
  return (
    <Button size="sm" colorScheme="teal" leftIcon={<EditIcon />} onClick={onClick}>
      작성
    </Button>
  );
};

export default AddButton;
