import React from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { Box, Text, Divider, Button } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import ListHeader from "@layout/ListHeader";
import DataTable from "@component/Table";
import { FILTER_OPTION, LABEL, ROUTE, API } from "./config";
import useEntities from "@data/useEntities";
import { Cell } from "react-table";
import AddButton from "@component/AddButton";
import moment from "moment";
import axios from "axios";
import writeXlsxFile from "write-excel-file";
import "moment/locale/ko";

const ListPage = (props: RouteProps) => {
  const query = props!.location!.search!;
  const { count, pageCount, data } = useEntities(API, query);
  const history = useHistory();

  const COLUMN = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "소송",
      accessor: "post",
      Cell: (props: Cell) => {
        return (
          <Button
            size="sm"
            onClick={(e: any) => {
              e.stopPropagation();
              history.push(`/post/${props.value.id}`);
            }}
          >
            {`${props.value.title} (${props.value.id})`}
          </Button>
        );
      },
    },
    {
      Header: "참가자",
      accessor: "name",
      Cell: (props: Cell) => {
        const { original } = props.row as any;

        return (
          <Button
            size="sm"
            onClick={(e: any) => {
              e.stopPropagation();
              if (original?.user?.id) {
                history.push(`/user/${original.user.id}`);
              }
            }}
          >
            {original?.name} {original?.user?.id ? `(${original.user.id})` : ""}
          </Button>
        );
      },
    },
    {
      Header: "등록일",
      accessor: "createdAt",
      Cell: (props: Cell) => {
        return <Text>{moment(props.value).format("LLL")}</Text>;
      },
    },
  ];
  const handleClickDownload = async () => {
    const queries = `?search=${query.split("filter=")?.[1]?.split("&")?.[0]}`;

    try {
      const res = await axios.get(`/requests/all${queries}`);
      const data = res.data;

      if (!data?.length) return;

      const aparts = Array.from(new Set(data.map((row: any) => row?.post?.apart)).values());

      const files = aparts.map((apart) => data.filter((row: any) => row.post.apart === apart));

      files.forEach((row: any) => {
        const HEADER = [
          {
            value: "은행",
          },
          {
            value: "계좌번호",
          },
          {
            value: "예금주",
          },
          {
            value: "주소1(도로명)",
          },
          {
            value: "주소1(상세)",
          },
          {
            value: "주소2(도로명)",
          },
          {
            value: "주소2(상세)",
          },
          {
            value: "분양 아파트 동",
          },
          {
            value: "분양 아파트 호수",
          },
          {
            value: "생년월일",
          },
          {
            value: "이메일",
          },
          {
            value: "주민등록번호",
          },
          {
            value: "이름",
          },
          {
            value: "핸드폰번호",
          },
        ];

        const ROW = row.map((r: any) => [
          {
            type: String,
            value: r?.accountName ?? "",
          },
          {
            type: String,
            value: r?.accountNumber ?? "",
          },
          {
            type: String,
            value: r?.accountOwner ?? "",
          },
          {
            type: String,
            value: r?.address1 ?? "",
          },
          {
            type: String,
            value: r?.address1Detail ?? "",
          },
          {
            type: String,
            value: r?.address2 ?? "",
          },
          {
            type: String,
            value: r?.address2Detail ?? "",
          },
          {
            type: String,
            value: r?.dong ?? "",
          },
          {
            type: String,
            value: r?.ho ?? "",
          },
          {
            type: String,
            value: r?.birth ?? "",
          },
          {
            type: String,
            value: r?.email ?? "",
          },
          {
            type: String,
            value: r?.jumin ?? "",
          },
          {
            type: String,
            value: r?.name ?? "",
          },
          {
            type: String,
            value: r?.phone ?? "",
          },
        ]);

        const d = [HEADER, ...ROW];

        writeXlsxFile(d, {
          fileName: `${row?.[0]?.post?.apart}_${new Date()}.xlsx`,
        });
      });
    } catch (err) {
      alert("현재 소송참여 인원을 다운로드 받을 수 없습니다. 조금 뒤 다시 시도해주세요");
    }
  };

  return (
    <Layout>
      <Wrapper>
        <ListHeader
          count={count}
          title={LABEL.title}
          subTitle={LABEL.subTitle}
          actions={
            <div style={{ display: "flex", gap: "8px" }}>
              <Button size={"sm"} bg={"Highlight"} onClick={handleClickDownload}>
                전체 다운로드
              </Button>
              <AddButton onClick={() => history.push(`/${ROUTE}/add`)} />
            </div>
          }
        />
        <Divider my={4} />
        <Box maxW="100vw" position="relative">
          <DataTable
            api={API}
            route={ROUTE}
            query={query}
            data={data}
            columns={COLUMN}
            filterOption={FILTER_OPTION}
            pageCount={pageCount}
          />
        </Box>
      </Wrapper>
    </Layout>
  );
};

export default ListPage;
