import React from "react";
import { Box, Text, Center } from "@chakra-ui/react";

const Splash = () => {
  return (
    <Center h="100vh" w="100vw">
      <Box d="flex" flexDirection="column" alignItems="center">
        <Text fontSize="4xl" fontWeight="bold">
          마이리펀드
        </Text>
      </Box>
    </Center>
  );
};

export default Splash;
