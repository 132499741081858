// BASE
export const ROUTE = "user";
export const API = "users";

export const FILTER_OPTION = [{ label: "전체", value: "search" }];

export const LABEL = {
  title: "사용자",
  subTitle: "사용자리스트 관리 페이지",
};

export const INIT_STATE = {
  userType: "",
  name: "",
  username: "",
  phone: "",
  code: "",
  notify_app: true,
  notify_comment: true,
  notify_etc: true,
  notify_marketing: true,
};

export const ROLE = [
  {
    label: "사용자",
    value: "USER",
  },
  {
    label: "관리자",
    value: "ADMIN",
  },
];

export const PROVIDER = [
  {
    label: "이메일",
    value: "EMAIL",
  },
  {
    label: "카카오",
    value: "KAKAO",
  },
  {
    label: "구글",
    value: "GOOGLE",
  },
];
