import React, { useEffect, useState } from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { Box, Button, Divider, Text } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import ListHeader from "@layout/ListHeader";
import DataTable from "@component/Table";
import { FILTER_OPTION, LABEL, ROUTE, API } from "./config";
import useEntities from "@data/useEntities";
import AddButton from "@component/AddButton";
import { Cell } from "react-table";
import moment from "moment";
import "moment/locale/ko";
import axios from "axios";

const ListPage = (props: RouteProps) => {
  const query = props!.location!.search!;
  const { count, pageCount, data } = useEntities(API, query);
  const history = useHistory();

  const COLUMN = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "아파트명",
      accessor: "apart",
    },
    {
      Header: "제목",
      accessor: "title",
    },
    {
      Header: "진행여부",
      accessor: "published",
      Cell: (props: Cell) => {
        return <Text>{props.value ? "진행중" : "마감"}</Text>;
      },
    },
    {
      Header: "참여자",
      accessor: "requests",
      Cell: (props: Cell) => {
        const requestsLength = (props.row.original as any)?.requests?.length ?? 0;
        const offlineLength = (props.row.original as any)?.offlinecount;
        const [offline, setOffline] = useState(0);

        useEffect(() => {
          setOffline(offlineLength);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [offlineLength]);

        const id = (props.row.original as any)?.id;

        const save = async () => {
          try {
            await axios.post(`/posts/${id}`, { offlinecount: offline });

            alert("오프라인 참여자가 저장되었습니다.");
          } catch (err) {
            alert("오프라인 참여자가 저장되지 않았습니다. 잠시 뒤에 다시 시도해주세요");
          }
        };

        return (
          <Text
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {`${requestsLength}`}
            {" + "}
            <input
              value={offline}
              onChange={(e) => {
                const { value } = e.target;
                if (value === "") {
                  setOffline(0);
                  return;
                }
                if (Number.isNaN(value)) {
                  return;
                }
                setOffline(Number(value));
              }}
              style={{ width: 50, backgroundColor: "ButtonFace", textAlign: "center" }}
            />
            명
            {offlineLength !== offline && (
              <Button size={"xs"} style={{ marginLeft: "8px" }} onClick={save}>
                저장
              </Button>
            )}
          </Text>
        );
      },
    },
    {
      Header: "등록일",
      accessor: "createdAt",
      Cell: (props: Cell) => {
        return <Text>{moment(props.value).format("LLL")}</Text>;
      },
    },
  ];

  return (
    <Layout>
      <Wrapper>
        <ListHeader
          count={count}
          title={LABEL.title}
          subTitle={LABEL.subTitle}
          actions={<AddButton onClick={() => history.push(`/${ROUTE}/add`)} />}
        />
        <Divider my={4} />
        <Box maxW="100vw" position="relative">
          <DataTable
            api={API}
            route={ROUTE}
            query={query}
            data={data}
            columns={COLUMN}
            filterOption={FILTER_OPTION}
            pageCount={pageCount}
          />
        </Box>
      </Wrapper>
    </Layout>
  );
};

export default ListPage;
