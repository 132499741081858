import React from "react";
import { useHistory } from "react-router-dom";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useFilters,
  TableOptions,
  Row,
  HeaderGroup,
  Cell,
  UsePaginationOptions,
} from "react-table";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, HStack, Button } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { DeleteIcon, TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import useDeleteModal from "../hooks/useDeleteModal";
import useDeletesModal from "../hooks/useDeletesModal";
import TableFilter from "./TableFilter";
import TablePagination from "./TablePagination";
import { deleteEntities, deleteEntity } from "@services/common";
import useEntities from "@data/useEntities";

type DataTableProp = {
  api: string;
  route: string;
  query: string;
  data: any;
  columns: any;
  pageCount: number;
  filterOption: { label: string; value: string }[];
  filter?: boolean;
};

const DataTable: React.FC<DataTableProp> = ({
  api,
  route,
  query,
  data,
  columns,
  pageCount,
  filterOption,
  filter = true,
}) => {
  const { dataMutate } = useEntities(api, query);
  const pageLimit = +(localStorage.getItem("page") ?? 10);
  const queries = React.useMemo(() => new URLSearchParams(query), [query]);
  const start = +(queries.get("start") ?? 0);
  const limit = +(queries.get("limit") ?? pageLimit);
  const filterValue = queries.get("filter") ?? "";
  const filterByValue = queries.get("filterBy") ?? filterOption[0].value;
  const initialSort = queries.get("sortBy") ? { id: queries.get("sort"), desc: queries.get("sortBy") === "DESC" } : {};
  const history = useHistory();
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [filterBy, setFilterBy] = React.useState(filterByValue);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: limit,
        pageIndex: start / limit,
        sortBy: [initialSort],
      },
      pageCount: pageCount,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
    } as TableOptions<UsePaginationOptions<{}>>,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => {
            const props = getToggleAllPageRowsSelectedProps();
            return <input type="checkbox" {...props} />;
          },
          Cell: ({ row }: any) => {
            const props = row.getToggleRowSelectedProps();
            return <input type="checkbox" {...props} />;
          },
        },
        ...columns,
        {
          id: "action",
          Header: "액션",
          Cell: ({ row }: { row: Row }) => {
            return (
              <HStack>
                <IconButton
                  aria-label="table-action"
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleDeleteModal(row.values.id)}
                  icon={<DeleteIcon />}
                  size="sm"
                />
              </HStack>
            );
          },
        },
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  React.useEffect(() => {
    const sort = sortBy[0] ? (sortBy[0].desc ? "DESC" : "ASC") : "";
    const dir = sortBy[0]?.id || "";
    let url = `${route}?start=${pageSize * pageIndex}&limit=${pageSize}`;
    url += `&filterBy=${filterBy}&filter=${filterValue}`;
    url += `&sortBy=${sort}&sort=${dir}`;
    history.push(url);
  }, [route, history, pageIndex, pageSize, sortBy, filterBy, filterValue]);

  const handleDeleteList = React.useCallback(
    async ({ ids }) => {
      await deleteEntities(api, ids);
      dataMutate();
    },
    [dataMutate, api]
  );

  const handleDelete = React.useCallback(
    async ({ id }) => {
      await deleteEntity(api, id);
      dataMutate();
    },
    [dataMutate, api]
  );

  const handlePush = React.useCallback(({ id }) => history.push(`/${route}/${id}`), [route, history]);

  const { setDeleteOpen, DeleteModal } = useDeleteModal({
    callback: async () => {
      await handleDelete({ id: selectedId! });
      setTimeout(() => setSelectedId(null), 300);
    },
  });

  const { setDeletesOpen, DeletesModal } = useDeletesModal({
    data: selectedFlatRows.map((el: Row) => el.values),
    callback: async () => {
      await handleDeleteList({
        ids: selectedFlatRows.map((el: Row) => el.values.id),
      });
      setTimeout(() => setSelectedId(null), 300);
    },
  });

  const handleDeleteModal = (id: number) => {
    setSelectedId(id);
    setDeleteOpen(true);
  };

  const handleDeletesModal = () => {
    setSelectedId(null);
    setDeletesOpen(true);
  };

  return (
    <>
      <DeleteModal />
      <DeletesModal />
      {filter && (
        <TableFilter
          arg={filterValue}
          route={route}
          limit={limit}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          filterOption={filterOption}
        />
      )}
      <Box maxW="100%" overflowX="auto">
        <Table {...getTableProps()} border="1px solid #eee" fontSize={13}>
          <Thead>
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th bg="rgba(0,0,0,0.03)" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Text as="span" isTruncated>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon mr="1" color="#080" />
                        ) : (
                          <TriangleUpIcon mr="1" color="#800" />
                        )
                      ) : (
                        ""
                      )}
                      {column.render("Header")}
                    </Text>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{ cursor: "pointer", background: "rgba(0,0,0,0.02)" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePush({ id: row.values.id });
                  }}
                >
                  {row.cells.map((cell: Cell) => {
                    const { id } = cell.column;
                    const isPrevent = id === "selection" || id === "action";
                    return (
                      <Td
                        {...cell.getCellProps()}
                        py={2}
                        onClick={(e) => {
                          if (isPrevent) e.stopPropagation();
                        }}
                      >
                        <Text as="span" isTruncated noOfLines={1} maxW={"100%"} minW={10}>
                          {cell.render("Cell")}
                        </Text>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {selectedFlatRows.length > 0 && (
          <HStack mt={3}>
            <Button
              variant="outline"
              colorScheme="red"
              size="sm"
              leftIcon={<DeleteIcon />}
              onClick={handleDeletesModal}
            >
              선택 삭제
            </Button>
          </HStack>
        )}
        <TablePagination pageSize={limit} pageIndex={start / limit} {...tableInstance} />
      </Box>
    </>
  );
};

export default DataTable;
