import React from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

type FileProp = {
  value?: string;
  setValue: any;
};

const UploadFile: React.FC<FileProp> = ({ value, setValue }) => {
  const [file, setFile] = React.useState<File | null>(null);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setValue(acceptedFiles[0]);
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleDelete = () => {
    setFile(null);
  };

  return (
    <Flex align="center">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button w={120} h={12} _hover={{ opacity: 0.9 }} _active={{ opacity: 0.8 }} mb={[2, 0]}>
          찾아보기
        </Button>
      </div>
      {!file && value && (
        <Box d="inline-block" p={2} noOfLines={1} borderWidth={1} mx={2} rounded="md">
          {value}
        </Box>
      )}
      {file && (
        <>
          <Box d="inline-block" p={2} noOfLines={1} borderWidth={1} mx={2} rounded="md">
            {file.name}
          </Box>
          <Button onClick={handleDelete}>X</Button>
        </>
      )}
    </Flex>
  );
};

export default React.memo(UploadFile);
