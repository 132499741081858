const components = {
  Button: {
    baseStyle: {
      fontWeight: "500",
      borderRadius: "4px",
    },
    defaultProps: {},
  },
};

export default components;
