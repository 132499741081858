import React from "react";
import { Switch, SwitchProps, FormControl, FormLabel } from "@chakra-ui/react";

interface SwitchInputProp extends SwitchProps {
  name: string;
  label?: string;
  handleChange: (name: string, e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchInput: React.FC<SwitchInputProp> = ({ name, label, value, handleChange }) => {
  return (
    <FormControl id={name} display="flex" alignItems="center">
      <FormLabel mb="0">{label}</FormLabel>
      <Switch isChecked={!!value} onChange={(e) => handleChange(name, e)} />
    </FormControl>
  );
};

export default SwitchInput;
