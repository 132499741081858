import React from "react";
import { HStack, Button, Box, Center, Image } from "@chakra-ui/react";
import ImageUploading, { ImageListType } from "react-images-uploading";

export const useImageUpload = (img: string, callback: () => void) => {
  const [images, setImages] = React.useState<any[]>([]);
  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const UploadImage = React.memo(() => (
    <ImageUploading multiple={false} value={images} onChange={onChange}>
      {({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
        <HStack>
          <Center
            w="120px"
            h="130px"
            bg="gray.50"
            color="gray.500"
            border="1px dashed #ccc"
            fontSize="xl"
            rounded="md"
            style={isDragging ? { background: "#eee" } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            +
          </Center>
          <Box w={200} h="130px" overflow="hidden">
            {!img && images.length === 0 && <Box h="full" bg="gray.100" />}
            {img && images.length === 0 && (
              <Box h="full" pos="relative">
                <Image objectFit="cover" src={img} />
                <Button size="sm" colorScheme="red" onClick={callback} position="absolute" top="0" right="0">
                  삭제
                </Button>
              </Box>
            )}
            {images.length > 0 && (
              <Box h="full" pos="relative">
                <Image objectFit="contain" src={images[0].dataURL} />
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={onImageRemoveAll}
                  position="absolute"
                  top="0"
                  right="0"
                  rounded="none"
                >
                  삭제
                </Button>
              </Box>
            )}
          </Box>
        </HStack>
      )}
    </ImageUploading>
  ));
  return { images, UploadImage };
};
