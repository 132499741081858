import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import uploadImage from "@services/image";
import { useDropzone } from "react-dropzone";
import "./editor.css";
import { Box } from "@chakra-ui/react";
// @ts-ignore
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

type QuilEditorProp = {
  value?: string;
  onChange: (value: string) => void;
};

const QuilEditor: React.FC<QuilEditorProp> = ({ value, onChange }) => {
  const editorRef = React.useRef<any>(null);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({});

  const onDrop = (acceptedFiles: File[]) => {
    try {
      acceptedFiles.forEach(async (file) => {
        if (!editorRef.current) return;
        const data = await uploadImage(file);
        const quill = editorRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", data);
        quill.setSelection(range.index + 1);
        quill.focus();
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  const modules = {
    imageResize: {
      // parchment: Quill.import('parchment'),
      modules: ["Resize", "DisplaySize"],
    },
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: open,
      },
    },
  };

  const formats = [
    "header",
    "bold",
    "size",
    "font",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "ordered",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  const HEIGHT = 300;

  return (
    <Box h={[HEIGHT + 70, HEIGHT + 40]}>
      <ReactQuill
        placeholder="자세한 설명을 입력하세요."
        ref={editorRef}
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        onChange={(value) => onChange(value)}
        style={{ height: HEIGHT }}
      />
      <Box className="container">
        <Box {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
        </Box>
      </Box>
    </Box>
  );
};

export default QuilEditor;
