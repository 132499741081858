import React from "react";
import { Box, HStack, Heading, Text, Spacer } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from "@chakra-ui/react";
import { Menu, MenuList, MenuItem, MenuDivider, MenuGroup } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { getPathName, ROUTE } from "@constants/config";
import { useUser } from "@data/useUser";

const Header = () => {
  const { userMutate } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerBody>
              <Menu defaultIsOpen={true}>
                <MenuList w="100vw" h="100vh">
                  <HStack px={3} py={2}>
                    <Heading
                      onClick={() => {
                        history.push("/");
                        onClose();
                      }}
                    >
                      마이리펀드
                    </Heading>
                    <Spacer />
                    <DrawerCloseButton />
                  </HStack>
                  {ROUTE.map((el) => (
                    <MenuGroup key={el.name + "_drawer_title"} title={el.name}>
                      {el.menus.map((menu) => (
                        <MenuItem
                          key={menu.label + "_drawer"}
                          onClick={() => {
                            history.push(menu.value);
                            onClose();
                          }}
                          fontSize={16}
                        >
                          <menu.icon />
                          <Text ml={2}>{menu.label}</Text>
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  ))}
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("jwt");
                      userMutate(null, false);
                    }}
                  >
                    로그아웃
                  </MenuItem>
                </MenuList>
              </Menu>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Box
        alignSelf="stretch"
        borderBottom="1px solid #eee"
        boxShadow="sm"
        alignItems="center"
        bg="#fff"
        d={["block", "none"]}
      >
        <HStack h={"60px"} alignItems="center" justifyContent="space-between" px={6}>
          <HStack>
            <Heading>마이리펀드</Heading>
            <Text>{getPathName(pathname)}</Text>
          </HStack>
          <HamburgerIcon fontSize={20} onClick={() => onOpen()} />
        </HStack>
      </Box>
    </>
  );
};

export default Header;
