import React from "react";
import { Textarea, TextareaProps, FormControl, FormLabel } from "@chakra-ui/react";

interface TextAreaProp extends TextareaProps {
  name: string;
  label?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProp> = ({ name, label, value, handleChange, ...rest }) => {
  return (
    <FormControl id={name}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea name={name} value={value} onChange={handleChange} {...rest} />
    </FormControl>
  );
};

export default TextArea;
