import React from "react";
import { Input, InputProps, FormControl, FormLabel } from "@chakra-ui/react";

interface TextInputProp extends InputProps {
  name: string;
  label?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProp> = ({ name, label, value, handleChange, ...rest }) => {
  return (
    <FormControl id={name}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input name={name} rounded="none" value={value} onChange={handleChange} {...rest} />
    </FormControl>
  );
};

export default TextInput;
