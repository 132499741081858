// BASE
export const ROUTE = "post";
export const API = "posts";

export const FILTER_OPTION = [{ label: "전체", value: "search" }];

export const LABEL = {
  title: "소송",
  subTitle: "소송리스트 관리 페이지",
};

export const INIT_STATE = {
  img: "",
  title: "",
  content: "",
  notify: null,
  expense: null,
  scope: null,
  target: null,
  paper: null,
  posibility: null,
  risk: null,
  notice: null,
  lawyer: null,
  contract: null,
  contractFile: null,
  files: [],
  addresss: [],
};
