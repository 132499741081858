import React from "react";
import { Input, Button, VStack, FormControl, FormLabel, FormErrorMessage, Alert, AlertIcon } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getMe, login } from "@services/auth";
import AuthContainer from "./AuthContainer";
import { useUser } from "@data/useUser";

const LoginPage = () => {
  const { userMutate } = useUser();
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string().required("이메일을 입력하세요"),
      password: Yup.string().required("비밀번호를 입력하세요"),
    }),
    onSubmit: async (values, { setStatus }) => {
      try {
        setStatus("");
        const body = values;
        const { data } = await login(body);
        localStorage.setItem("jwt", data);
        const result = await getMe();
        if (result?.data?.userType !== "ADMIN") {
          throw new Error("권한 실패");
        }
        userMutate();
      } catch (err) {
        localStorage.removeItem("jwt");
        setStatus("로그인 실패");
      }
    },
  });

  const { status, errors, touched, getFieldProps, submitForm, isValid, dirty } = formik;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <AuthContainer title="로그인" subTitle="로그인을 해주세요">
      <form onSubmit={handleSubmit}>
        <VStack w={["100vw", "350px"]} spacing={3} p={[3, 0]}>
          {status && (
            <Alert status="error">
              <AlertIcon />
              {status}
            </Alert>
          )}
          <FormControl isInvalid={(errors.email && touched.email) as boolean}>
            <FormLabel htmlFor="email">이메일</FormLabel>
            <Input id="email" placeholder="이메일" {...getFieldProps("email")} />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={(errors.password && touched.password) as boolean}>
            <FormLabel htmlFor="password">비밀번호</FormLabel>
            <Input id="password" placeholder="비밀번호" type="password" {...getFieldProps("password")} />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <Button type="submit" isFullWidth disabled={!(isValid && dirty)} colorScheme={"blue"}>
            로그인
          </Button>
        </VStack>
      </form>
    </AuthContainer>
  );
};

export default LoginPage;
