import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Heading, Button, Divider, VStack, SimpleGrid, Text, Box, HStack } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import TextInput from "@component/TextInput";
import SwitchInput from "@component/SwitchInput";
import { EditIcon } from "@chakra-ui/icons";
import { INIT_STATE, LABEL, PROVIDER, ROLE } from "./config";
import { useImageUpload } from "@hooks/useImageUpload";
import uploadImage from "@services/image";
import useModal from "@hooks/useModal";
import useSWR from "swr";
import { API } from "./config";
import ListHeader from "@layout/ListHeader";
import { addEntity, updateEntity } from "@services/common";
import { ACTION, reducer } from "@type/context";
import SelectInput from "@component/SelectInput";
import moment from "moment";
import "moment/locale/ko";

const DetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, mutate } = useSWR(id ? `${API}/${id}` : null);
  const isUpdate = !!id;
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, INIT_STATE);
  const { images, UploadImage } = useImageUpload(state.avatar, () =>
    dispatch({
      type: ACTION.UPDATE,
      payload: { name: "avatar", value: "" },
    })
  );
  const { success, fail } = useModal();

  React.useEffect(() => {
    if (data) dispatch({ type: ACTION.SET, payload: { data } });
  }, [data]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let avatar = state.avatar || "";
      if (images.length > 0) {
        avatar = await uploadImage(images[0].file);
      }
      if (isUpdate) {
        const {
          id,
          createdAt,
          updatedAt,
          likePosts,
          likeItems,
          pollComments,
          pollVotes,
          answers,
          deletedAt,
          kakaoid,
          googleid,
          requests,
          password,
          ...rest
        } = state;
        await updateEntity(API, id, { ...rest, avatar });
        await mutate();
        success();
      } else {
        await addEntity(API, { ...state, avatar });
        history.goBack();
      }
    } catch (err) {
      fail();
    }
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    dispatch({ type: ACTION.UPDATE, payload: { name, value } });
  };

  const handleToggle = (name: string, e: React.ChangeEvent<any>) => {
    dispatch({
      type: ACTION.UPDATE,
      payload: { name, value: e.target.checked },
    });
  };

  return (
    <Layout>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <ListHeader
            title={LABEL.title + " 디테일"}
            subTitle={LABEL.title + `를 ${isUpdate ? "수정" : "생성"}하세요`}
            actions={
              <HStack>
                <Button type="submit" colorScheme="teal" size="sm" leftIcon={<EditIcon />}>
                  {isUpdate ? "수정완료" : "생성"}
                </Button>
              </HStack>
            }
          />
          <Divider my={4} />
          <VStack spacing={4} align="stretch">
            <Heading color="gray.400" fontSize="16px" w="100%">
              {"사용자 정보"}
            </Heading>
            <SimpleGrid w="full" columns={[2, 3]} spacing={2}>
              <TextInput
                name="username"
                label="이름"
                placeholder="이름을 입력하세요."
                value={state.username ?? ""}
                handleChange={handleChange}
              />
              <TextInput
                name="email"
                label="이메일"
                placeholder="이메일을 입력하세요."
                value={state.email ?? ""}
                handleChange={handleChange}
              />
              <TextInput
                name="birth"
                label="생년월일"
                placeholder="생년월일를 입력하세요."
                value={state.birth ?? ""}
                handleChange={handleChange}
              />
              <TextInput
                name="phone"
                label="전화번호"
                placeholder="전화번호를 입력하세요."
                value={state.phone ?? ""}
                handleChange={handleChange}
              />
              <SelectInput
                label="구분"
                name="userType"
                value={state.userType}
                handleChange={handleChange}
                options={ROLE}
              />
              <SelectInput
                label="가입유형"
                name="provider"
                value={state.provider}
                handleChange={handleChange}
                options={PROVIDER}
              />
            </SimpleGrid>
            <Divider />
            <Heading color="gray.400" fontSize="16px" w="100%">
              {"탈퇴 정보"}
            </Heading>
            <SwitchInput name="deleted" label="탈퇴여부" value={state.deleted} handleChange={handleToggle} />
            {state.deleted && (
              <>
                <Box>
                  <Text mb={2}>{`탈퇴일`}</Text>
                  <Text>{`${moment(state.deletedAt).format("LL")}`}</Text>
                </Box>
                <TextInput
                  name="delete_reason"
                  label="탈퇴이유"
                  placeholder="탈퇴이유를 입력하세요."
                  value={state.delete_reason ?? ""}
                  handleChange={handleChange}
                />
              </>
            )}
            <Divider />
            <Heading color="gray.400" fontSize="16px" w="100%">
              {"알림 정보"}
            </Heading>
            <SimpleGrid w="full" columns={[2, 4]} spacing={2}>
              <SwitchInput name="marketing" label="마케팅 알림" value={state.marketing} handleChange={handleToggle} />
            </SimpleGrid>
            <Divider />
            <Heading color="gray.400" fontSize="16px" w="100%">
              {"이미지"}
            </Heading>
            <UploadImage />
          </VStack>
        </form>
      </Wrapper>
    </Layout>
  );
};

export default DetailPage;
