import React from "react";
import { HStack, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import SelectInput from "@component/SelectInput";
import { useHistory } from "react-router-dom";

type TableFilterProp = {
  arg: string;
  route: string;
  limit: number;
  filterBy: string;
  setFilterBy: (e: string) => void;
  filterOption: { label: string; value: string }[];
};

const TableFilter: React.FC<TableFilterProp> = ({ arg, route, limit, filterBy, setFilterBy, filterOption }) => {
  const history = useHistory();
  const [value, setValue] = React.useState(arg);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    history.push(`${route}?start=0&limit=${limit}&filterBy=${filterBy}&filter=${value}`);
  };

  return (
    <HStack mb={2} alignItems="flex-end">
      <SelectInput
        w={150}
        name="table-filter"
        value={filterBy}
        options={filterOption}
        handleChange={(e) => setFilterBy(e.target.value)}
      />
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <InputGroup>
          <InputLeftElement children={<SearchIcon color="gray.300" />} />
          <Input
            rounded="none"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={`검색어를 입력하세요`}
          />
        </InputGroup>
      </form>
    </HStack>
  );
};

export default TableFilter;
