const styles = {
  global: {
    body: {
      bg: "white",
      color: "black",
    },
    a: {},
    input: {},
  },
};

export default styles;
