import React from "react";
import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Redirect, RouteProps, Switch } from "react-router-dom";

// AUTH
import LoginPage from "@auth/Login";

// MAIN
import HomePage from "@module/main";

// ADMIN-TAB
import PostPage from "@module/admin-tab/post";
import PostDetailPage from "@module/admin-tab/post/DetailPage";
import RequestPage from "@module/admin-tab/request";
import RequestDetailPage from "@module/admin-tab/request/DetailPage";

// USER-TAB
import UserPage from "@module/user-tab/user";
import UserDetailPage from "@module/user-tab/user/DetailPage";

import NotFound from "@component/NotFound";
import { useUser } from "@data/useUser";
import Splash from "@layout/Splash";

const PrivateRoute = (props: RouteProps) => {
  const { user } = useUser();
  if (!user || !(user?.userType === "ADMIN")) return <Redirect to="/login" />;
  return <Route {...props} />;
};

export const AppRoute = () => {
  const { isLoading } = useUser();
  if (isLoading) return <Splash />;
  return (
    <Router>
      <Box maxH="100vh" overflow="hidden">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute exact path="/" component={HomePage} />
          {/* Request */}
          <PrivateRoute exact path="/Request" component={RequestPage} />
          <PrivateRoute exact path="/Request/add" component={RequestDetailPage} />
          <PrivateRoute exact path="/Request/:id" component={RequestDetailPage} />

          {/* FAQ */}
          <PrivateRoute exact path="/post" component={PostPage} />
          <PrivateRoute exact path="/post/add" component={PostDetailPage} />
          <PrivateRoute exact path="/post/:id" component={PostDetailPage} />

          {/* USER */}
          <PrivateRoute exact path="/user" component={UserPage} />
          <PrivateRoute exact path="/user/add" component={UserDetailPage} />
          <PrivateRoute exact path="/user/:id" component={UserDetailPage} />
          <Route component={NotFound} />
        </Switch>
      </Box>
    </Router>
  );
};
