import React from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { Box, Text, Divider } from "@chakra-ui/react";
import Layout from "@layout/Layout";
import Wrapper from "@layout/Wrapper";
import ListHeader from "@layout/ListHeader";
import DataTable from "@component/Table";
import { FILTER_OPTION, LABEL, ROUTE, API } from "./config";
import moment from "moment";
import "moment/locale/ko";
import useEntities from "@data/useEntities";
import { Cell } from "react-table";
import AddButton from "@component/AddButton";

const ListPage = (props: RouteProps) => {
  const query = props!.location!.search!;
  const { count, pageCount, data } = useEntities(API, query);
  const history = useHistory();

  const COLUMN = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "이름",
      accessor: "username",
    },
    {
      Header: "권한",
      accessor: "userType",
    },
    {
      Header: "핸드폰",
      accessor: "phone",
    },
    {
      Header: "가입일",
      accessor: "createdAt",
      Cell: (props: Cell) => {
        return <Text>{moment(props.value).format("LLL")}</Text>;
      },
    },
  ];

  return (
    <Layout>
      <Wrapper>
        <ListHeader
          count={count}
          title={LABEL.title}
          subTitle={LABEL.subTitle}
          actions={<AddButton onClick={() => history.push(`/${ROUTE}/add`)} />}
        />
        <Divider my={4} />
        <Box maxW="100vw" position="relative">
          <DataTable
            api={API}
            route={ROUTE}
            query={query}
            data={data}
            columns={COLUMN}
            filterOption={FILTER_OPTION}
            pageCount={pageCount}
          />
        </Box>
      </Wrapper>
    </Layout>
  );
};

export default ListPage;
