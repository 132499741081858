import axios from "axios";

export const addEntity = (API: string, body: any) => {
  return axios.post(`${API}`, body);
};

export const updateEntity = (API: string, id: number | string, body: any) => {
  return axios.put(`${API}/${id}`, body);
};

export const deleteEntity = (API: string, id: number | string) => {
  return axios.delete(`${API}/${id}`);
};

export const deleteEntities = (API: string, ids: string[]) => {
  const promises = ids.map((id) => axios.delete(`${API}/${id}`));
  return Promise.all(promises);
};
