import React from "react";
import { Box, Button, Divider, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import TextInput from "./TextInput";
import TextArea from "./TextArea";

type FileProp = {
  files: [];
  setValue: any;
};

const Element = ({ file, setValue, setRemove }: any) => {
  const [title, setTitle] = React.useState(file.title);
  const [content, setContent] = React.useState(file.content);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e: React.ChangeEvent<any>) => {
    setContent(e.target.value);
  };

  const handleSetValue = () => {
    setValue({ title, content });
  };

  return (
    <Box mb={4}>
      <TextInput
        name="title"
        label="파일명"
        placeholder="파일명을 입력하세요."
        value={title}
        handleChange={handleChange}
      />
      <Box h={2} />
      <TextArea
        name="content"
        label="내용"
        placeholder="내용을 입력하세요."
        value={content}
        handleChange={handleContentChange}
      />
      <HStack mt={2}>
        <Button colorScheme="teal" size="sm" onClick={handleSetValue}>
          {"수정"}
        </Button>
        <Button size="sm" onClick={setRemove}>
          {"삭제"}
        </Button>
      </HStack>
    </Box>
  );
};

const Files: React.FC<FileProp> = ({ files, setValue }) => {
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");

  const handleChange = (e: React.ChangeEvent<any>) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e: React.ChangeEvent<any>) => {
    setContent(e.target.value);
  };

  const handleAdd = () => {
    setValue([...files, { title, content }]);
    setTitle("");
    setContent("");
  };

  const handleUpdate = (val: any, index: number) => {
    setValue([...files.map((el, i) => (i === index ? val : el))]);
  };

  const handleRemove = (index: number) => {
    setValue([...files.filter((el, i) => i !== index)]);
  };

  return (
    <Box>
      <Text mb={2} fontWeight="500">
        제출 필요서류
      </Text>
      <TextInput
        name="title"
        label="파일명"
        placeholder="파일명을 입력하세요."
        value={title}
        handleChange={handleChange}
      />
      <Box h={2} />
      <TextArea
        name="content"
        label="내용"
        placeholder="내용을 입력하세요."
        value={content}
        handleChange={handleContentChange}
      />
      <Button colorScheme="teal" size="sm" mt={2} onClick={handleAdd}>
        {"추가"}
      </Button>
      <Divider my={4} />
      <SimpleGrid columns={[1, 2]} spacing={4}>
        {files.map((file: any, index: number) => (
          <Element
            key={file.title}
            file={file}
            setValue={(val: any) => handleUpdate(val, index)}
            setRemove={() => handleRemove(index)}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default React.memo(Files);
