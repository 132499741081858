import React from "react";
import { Box, Button, HStack, Flex, Center, Text } from "@chakra-ui/react";
import TextInput from "./TextInput";

const Address = ({ index, address, setValue, setRemove }: any) => {
  const [dong, setDong] = React.useState(address.dong);
  const [ho, setHo] = React.useState(address.ho);
  const [price, setPrice] = React.useState(address.price);

  const handleSetValue = () => {
    setValue({ dong, ho, price });
  };

  return (
    <Flex>
      <Center w={32} h={10}>
        <Text fontSize={13}>{index + 1}</Text>
      </Center>
      <TextInput
        name="dong"
        placeholder="동을 입력하세요."
        value={dong}
        handleChange={(e) => setDong(e.target.value)}
      />
      <Box w={2} />
      <TextInput name="ho" placeholder="호을 입력하세요." value={ho} handleChange={(e) => setHo(e.target.value)} />
      <Box w={2} />
      <TextInput
        name="price"
        placeholder="환급액을 입력하세요."
        value={price}
        handleChange={(e) => setPrice(e.target.value)}
      />
      <Box w={4} />
      <HStack>
        <Button colorScheme="teal" onClick={handleSetValue}>
          {"수정"}
        </Button>
        <Button onClick={setRemove}>{"삭제"}</Button>
      </HStack>
    </Flex>
  );
};

export default React.memo(Address);
