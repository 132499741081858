import { AiOutlineCalendar, AiOutlineInfoCircle, AiOutlineUser } from "react-icons/ai";

export const TITLE = "ADMIN";

export const ROUTE = [
  {
    name: "관리자",
    menus: [
      { label: "소송", value: "/post", icon: AiOutlineInfoCircle },
      { label: "소송참여", value: "/request", icon: AiOutlineCalendar },
    ],
  },
  {
    name: "사용자",
    menus: [{ label: "사용자", value: "/user", icon: AiOutlineUser }],
  },
];

export const getPathName = (path: string) => {
  const paths = ROUTE.reduce((acc: any, cur: any) => [...acc, ...cur.menus], []);
  return paths.find((el: any) => path.startsWith(el.value))?.label || "";
};
